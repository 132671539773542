import React, { useRef, useEffect, useState } from "react";
import useSize from "@react-hook/size"; // npm i @react-hook/size
import loadable from "@loadable/component";
import GalleryModal from "./gallery-modal.js";

import "../../styles/blocks/gallery-carousel-fluid.scss";
const Image = loadable(() => import("./image.js"));

/**
 * @fuction GalleryCarousel - Renvoie le composant carousel.
 * @param {object} props - Props du composant.
 * 
 * @description 
 * - {string} [id] - id du carousel. 
 * - {string} [className] - classe(s) du carousel. 
 * - {number||false} fixedImageNumber - Nombre d'images affichées peut import la taille du carousel. Si false, carousel adaptatif.
 * - {boolean} openingModal - Determine si le click déclenche l'ouverture de la modal. 
 * - {string} typeButton - Determine le type de boutton du carousel (flèches/bulles).
 * - {number} activeImage - Determine l'index actif des images du carousel.
 * - {function} setActiveImage - Setter activeImage.
 * - {array} content - Ensemble des images du carousel.
 * - {boolean} isOpen - Détermine si la modal est ouverte/fermée.
 * - {function} setIsOpen - Setter isOpen.
 * 
 * @returns JSX
 */

const remToPX = (rem) => {
  try {
    if (rem.includes("px")) {
      return parseFloat(rem.split("px")[0]);
    }
    if (rem.includes("rem")) {
      return rem.split("rem")[0] * parseFloat(getComputedStyle(document.documentElement).fontSize);
    }
    return rem;
  } catch(e) {
    console.error("impossible to convert rem to px:", rem); //eslint-disable-line
    return 0;
  }
};
 
const GalleryCarouselFluid = (props) => {
  const {
    id,
    className,
    fixedImageNumber,
    openingModal,
    activeImage,
    setActiveImage,
    content,
    isOpen,
    setIsOpen,
  } = props; 

  /**
   * @param {useRef} carouselRef
   * @description Permet de récupérer la longueur du carousel et effectuer des calculs.
   */
  const carouselRef = useRef();
  const ghostImage = useRef();
  const imageRef = useRef();
  const animationRequest = useRef();
  const [isHover, setHover] = useState(false);
  const [gap, setGap] = useState(0);
  const [speed, setSpeed] = useState(0);
  const [wrapperLeft, setWrapperLeft] = useState(0);
  const setMouseX = useState(-1)[1];
  /**
   * @param {useSize} width
   * @description Taille du carousel
   */
  const [imageWidth] = useSize(imageRef);
  const [width] = useSize(carouselRef);

  useEffect(() => {
    if (carouselRef.current) {
      const gap = remToPX(getComputedStyle(carouselRef.current).getPropertyValue("--gap"));
      setGap(gap);
      const speed = parseInt(getComputedStyle(carouselRef.current).getPropertyValue("--speed") || 1);
      setSpeed(speed);
    }
  }, [carouselRef, width]);

  useEffect(() => {
    if (carouselRef.current && width) {
      const animation = () => {
        if (isHover) {
          return;
        }
        setWrapperLeft(oldValue => {
          if (oldValue <= -(content.length*(imageWidth + gap))) {
            return 0;
          }
          return oldValue - speed;
        });
        animationRequest.current = requestAnimationFrame(animation);
      };
      animationRequest.current = requestAnimationFrame(animation);
      return () => {
        try {
          cancelAnimationFrame(animationRequest.current);
        } catch {
          console.log("couldn't stop animation", animationRequest); //eslint-disable-line
        }
      };
    }
  }, [carouselRef, width, isHover, imageWidth]);

  const dragOverHandler = (e) => {
    e.preventDefault();
    setMouseX(oldX => {
      if (oldX !== -1) {
        let delta = (e.pageX || e.touches[0].pageX) - oldX;
        setWrapperLeft(oldValue => {
          const newValue = oldValue + delta;
          if (oldValue <= -(content.length*(imageWidth + gap))) {
            return 0;
          }
          if (newValue > 0) {
            return -(content.length*(imageWidth + gap)) + newValue;
          }
          return newValue;
        });
      }
      return (e.pageX || e.touches[0].pageX);
    });
  };

  const dragStartHandler = (e) => {
    e.dataTransfer.setDragImage(ghostImage.current, 0, 0);
  };

  const dragEndHandler = (e) => {
    e.preventDefault();
    setMouseX(-1);
  };

  /**
   * @description Render composant carousel.
   * - {number} --nieme-image - Détermine de combien de fois la longueur de la translation doit s'effectuée. Elle dépend de l'index de l'image active ainsi que du nombre d'image devant être affichée en même temps sur le carousel. Math.floor() permet de ne pas slider si on n'a pas encore atteint le bon index.
   * 
   * - fixedImageNumber - Chargement d'une image "medium" lorsque le carousel est adaptatif(800px suffiront à couvrir les slides). Lorsqu'il s'agit d'un nombre fixe (et encore plus si la valeur est 1), chargement de l'image en "full" pour qu'elles puissent prendre le maximum de place.
   */

  return (
    <>
      <div
        ref={ carouselRef }
        id={ id }
        className={ className+" fluid" } 
        style={ { 
          ...(props.style || {}),
          "--wrapper-left": wrapperLeft,
          "--max-wrapper": (content.length*(imageWidth + gap)),
          "--total-width": width
        } }
        onDrop={ (e) => { e.preventDefault(); } }
        onTouchMove={ dragOverHandler }
        onTouchEnd={ dragEndHandler }
        onDragOver={ dragOverHandler }
        onMouseOver={() => setHover(true) }
        onMouseLeave={() => setHover(false) }
      >
        <img
          ref={ ghostImage }
          src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAABhGlDQ1BJQ0MgcHJvZmlsZQAAKJF9kT1Iw0AcxV9TS0VaHAwo4pChOlkQFemoVShChVArtOpgPvoFTRqSFBdHwbXg4Mdi1cHFWVcHV0EQ/ABxdHJSdJES/5cUWsR4cNyPd/ced+8ArllVNKtnAtB028ykkkIuvyqEXxHCIKLgkZAUy5gTxTR8x9c9Amy9i7Ms/3N/jqhasBQgIBDPKoZpE28Qz2zaBuN9Yl4pSyrxOfG4SRckfmS67PEb45LLHMvkzWxmnpgnFkpdLHexUjY14mnimKrplM/lPFYZbzHWqnWlfU/2wkhBX1lmOs0RpLCIJYgQIKOOCqqwEadVJ8VChvaTPv5h1y+SSyZXBQo5FlCDBsn1g/3B726t4tSklxRJAqEXx/kYBcK7QKvhON/HjtM6AYLPwJXe8deaQOKT9EZHix0B/dvAxXVHk/eAyx1g6MmQTMmVgjS5YhF4P6NvygMDt0Dfmtdbex+nD0CWukrfAAeHwFiJstd93t3b3du/Z9r9/QCTXHK0RoF3cwAAAAZiS0dEAP8A/wD/oL2nkwAAAAlwSFlzAAAuIwAALiMBeKU/dgAAAAd0SU1FB+YFFw0VGVLhb+4AAAAZdEVYdENvbW1lbnQAQ3JlYXRlZCB3aXRoIEdJTVBXgQ4XAAAAC0lEQVQI12NgAAIAAAUAAeImBZsAAAAASUVORK5CYII="
          style={{
            top: "-100vh",
            left: "-100vw",
            position:"absolute",
          }}
          aria-hidden="true"
        />
        <div
          className={ (openingModal) ? ("modal-possible") : ("") }
        >
          { content.map((image, i) => {
            return (
              <div
                ref={ i === 0 ? imageRef : null }
                draggable={ true }
                onDragStart={ dragStartHandler }
                onDragEnd={ dragEndHandler }
                className="wrapper-image"
                key={ `wrapper-image-${i}` }
                style={{
                  "--img-left": (((1+i)*imageWidth+i*gap)*-1 < wrapperLeft)
                    ? "0px"
                    : (content.length*(imageWidth + gap))+"px"
                }}
                onClick={ (openingModal) 
                  ? () => {  
                    setActiveImage(i); 
                    setIsOpen(!isOpen);
                  } : false }
              >
                <Image
                  attributes={ image.attributes } 
                  size={ fixedImageNumber ? "full" : "medium" }
                />
              </div>
            );}) 
          }
        </div>
        <div className="progress">
          <div></div>
        </div>
      </div>
      { openingModal 
        ? (<GalleryModal
          title="Agrandir"
          isOpen ={ isOpen }
          setIsOpen ={ setIsOpen }
          activeImage={ activeImage }
          setActiveImage={ setActiveImage }
          content={ content }
        />)
        : (<></>)
      }
    </>
  );
};

export default GalleryCarouselFluid;  
